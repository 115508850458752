
.admin-layout {
    margin-left: $main-sidebar-width;
}

.ant-layout-has-sider.page-layout {
    position: relative;
    margin-left: $page-sidebar-width;
}

.page-title {
    display: flex;
    flex: auto;
    margin: 0;
    font-size: 16px;
    line-height: 21px;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;

    .title {
        font-size: 16px;
        line-height: 21px;
        font-weight: normal;
        vertical-align: middle;
        margin-right: 20px;
        margin-bottom: 0;
    }
}

.page-tools {
    white-space: nowrap;

    .ant-btn {
        margin: 0 0 0 15px;
    }
}

.page-sider {
    border-right: 1px solid $page-border-color;
}

.page-sider-title {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 23px;
    margin-bottom: 15px;
    color: rgba(0, 0, 0, 0.85);

    h1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 0;
    }
}

.page-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $page-border-color;
}

.page-content {
    position: relative;
    margin: 0;
    min-width: 400px;
    padding: $page-padding;

    .table table {
        border: 1px solid $page-border-color;
    }
}

.item-list {
    .ant-list-item {
        padding-left: 15px !important;

        &:hover {
            background-color: $background-color-light;
        }
    }
}

.error-page {
    .ant-layout-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
