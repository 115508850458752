.upload-wrapper {
    padding: 44px 0;
}

.asset-gallery {
    .item-list-empty {
        text-align: center;
        padding: 25vh 0;
    }
}

.asset-link {
    .ant-card-meta-title {
        transition: color $animation-duration-base $ease-in-out;
    }

    &:hover {
        .ant-card-meta-title {
            color: $link-hover-color;
        }
    }
}

.asset-list-header {
    margin-bottom: $padding-lg;
}

.asset-list-content {
    position: relative;
}

.asset-item {
    cursor: pointer;
    background-color: transparent;
    transition: all 350ms ease;

    &:hover {
        & .ant-image-mask {
            opacity: 1;
        }

        & .ant-card-meta-title {
            color: $link-hover-color;
        }
    }

    &:hover {
        .ant-image-mask {
            opacity: 1;
        }
    }

    .ant-image-mask {
        position: absolute;
        inset: 0;
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.3s;
        border-radius: $border-radius-base;

        .ant-image-mask-info {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0 4px;

            .anticon {
                margin-right: 4px;
            }
        }
    }

    .ant-card-cover {
        position: relative;
        overflow: hidden;
        border-radius: $border-radius-base;

        img {
            border-radius: $border-radius-base;
            transition: box-shadow 0.2s $ease-in-out;
        }
    }

    .ant-card-body {
        padding: 12px 0 !important;
    }

    .ant-card-meta-detail {
        .ant-card-meta-title {
            font-size: 14px;
            margin: 0 !important;
        }
    }

    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        right: 0;
        top: 0;
        display: inline-block;
        color: #fff;
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        font-weight: normal;
        border-radius: 50%;
        opacity: 0;
        background-color: $primary-color;
        box-shadow: $btn-primary-shadow;
        transition: all $animation-duration-base $ease-in-out-quint;
    }

    &.asset-selected {
        &::after {
            width: 30px;
            height: 30px;
            right: -10px;
            top: -10px;
            opacity: 1;
            content: "\2713";
        }

        img {
            box-shadow: 0 0 0 3px fadeout($primary-color, 40%);
        }
    }
}

.ant-modal-root {
    .asset-modal {
        .ant-modal-body {
            padding: 0;
        }

        .asset-modal-content {
            padding: $modal-body-padding;

            .ant-list {
                margin-bottom: -16px;
            }

            .ant-list-pagination {
                margin: 0;
            }

            .ant-pagination {
                margin-bottom: 16px;
            }
        }
    }
}

.asset-modal-view {
    .asset-modal-content {
        padding: $modal-body-padding;
    }
}

.asset-modal-edit {
    .asset-modal-content {
        padding: $modal-body-padding;
    }
}

.asset-frame {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 400px;
    max-height: 430px;
    border-radius: $border-radius-base;
    background-color: $image-frame-background;

    img {
        height: auto;
        max-height: inherit;
        max-width: 100%;
    }
}

.asset-item-preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
    
    .asset-item-icon {
        color: $text-color-secondary;
    }
}

.asset-item-icon {
    width: 30%;
    height: 30%;
    color: $text-color-secondary;

    svg {
        width: 100%;
        height: 100%;
    }
}

.asset-item-background {
    display: block;
    background-color: $background-color-base;
}

.asset-info {
    position: relative;
    padding: $padding-md;
    background-color: $background-color-light;
    border-radius: $border-radius-base;
    margin-bottom: $padding-lg;

    .asset-info-title {
        font-weight: 500;
        font-style: normal;
    }

    .asset-info-description {
        color: $text-color-secondary;
        margin: 0;
    }
}

.asset-input {
    position: relative;
    display: block;
    margin-top: 20px;

    .ant-list-empty-text {
        padding: 0;
    }

    .ant-list-item {
        position: relative;

        &:hover {
            .draggable-handle {
                left: -10px;
                top: -10px;
                width: $btn-circle-size;
                height: $btn-circle-size;
                opacity: 1;
            }

            .asset-input-remove {
                right: -10px;
                top: -10px;
                width: $btn-circle-size;
                height: $btn-circle-size;
                opacity: 1;
            }
        }
    }
}

.asset-input-remove {
    position: absolute !important;
    opacity: 0;
    width: 0;
    min-width: 0;
    height: 0;
    right: 4px;
    top: 4px;
    transition: all $animation-duration-base $ease-in-out-quint;
}

.modal-spin-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 473px;
}

.draggable {
    .draggable-handle {
        position: absolute;
        opacity: 0;
        width: 0;
        min-width: 0;
        height: 0;
        left: 4px;
        top: 4px;
        transition: all $animation-duration-base $ease-in-out-quint;
    }

    &.is-dragging {
        position: relative;
        z-index: 100;

        .asset-item {
            transform: scale(1.05);
            opacity: 0.85;
        }

        .ant-card-cover {
            box-shadow: $shadow-2;
        }
    }
}
